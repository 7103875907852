<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="证书成员批量导入"/>
        <div class="form">
            <div class="title">
                证书成员批量导入
            </div>
            <el-upload
                class="upload-demo"
                drag
                :action="`/api/cert/member/upload/excel/${$route.params.id}`"
                :headers="{'Authorization': $store.getters.token}"
                :limit="1"
                :on-success="onSuccess"
                :on-error="onError"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">
                    <a href="/api/cert/download/template1.xlsx" target="_blank">
                        下载excel模板
                    </a>
                </div>
            </el-upload>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        methods: {
            onSuccess(resp, file, fileList) {
                console.log(resp, file, fileList)
                if (resp.state == 200) {
                    this.$message.success(resp.msg)
                    this.$router.replace(`/main/certs/m/${this.$route.params.id}`)
                } else {
                    this.$message.error(resp.msg)
                }

            },
            onError(err){
                console.log(err)
                this.$message.error('导入失败！')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin: 10px auto;
        text-align: center;
        max-width: 380px;

        .title {
            padding: 20px;
            font-weight: bold;
            color: #4f9fe7;
        }
    }
</style>
